<template>
    <section class="my-0" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-md-center">
                <div class="col-lg-6">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <base-alert shadow type="primary">
                                <span class="lead text-white">{{$t('preference.name')}}</span>
                            </base-alert>
                            <base-alert v-if="alert" :type="alert_type">
                                <span class="alert-inner--text">{{alert_message}}</span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alert = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>
                            <form class="mt-5" role="form">
                                <base-checkbox v-model="model.notification.all">
                                    <label style="font-weight:bold;">{{$t('preference.label.notification.all')}}</label>
                                </base-checkbox>

                                <base-button class="mt-4" block type="primary" @click="submitModel()">{{$t('preference.label.submit')}}</base-button>
                            </form>
                            <!-- Add field for the user to see feedbacks he already sent. -->


                            <b-form-group v-if="false">
                                <b-form-tags v-model="value" size="md" add-on-change no-outer-focus  class="input-group-alternative">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                                        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                                <b-form-tag @remove="removeTag(tag)"
                                                            :title="tag"
                                                            :disabled="disabled"
                                                            variant="primary"
                                                            size="lg">{{$t('reply.request.property.' + tag)}}</b-form-tag>
                                            </li>
                                        </ul>
                                        <b-form-select v-bind="inputAttrs"
                                                       v-on="inputHandlers">                                                       
                                            <template v-slot:first>
                                                <option disabled value="">Escolha os tipos de im�veis</option>
                                                <option value="apartment">{{$t('reply.request.property.apartment')}}</option>
                                                <option value="house">{{$t('reply.request.property.house')}}</option>
                                                <option value="store">{{$t('reply.request.property.store')}}</option>
                                                <option value="condo">{{$t('reply.request.property.condo')}}</option>
                                                <option value="penthouse">{{$t('reply.request.property.penthouse')}}</option>
                                                <option value="kitchenette">{{$t('reply.request.property.kitchenette')}}</option>
                                                <option value="loft">{{$t('reply.request.property.loft')}}</option>
                                                <option value="studio">{{$t('reply.request.property.studio')}}</option>
                                                <option value="office">{{$t('reply.request.property.office')}}</option>
                                                <option value="shed">{{$t('reply.request.property.shed')}}</option>
                                                <option value="garage">{{$t('reply.request.property.garage')}}</option>
                                                <option value="warehouse">{{$t('reply.request.property.warehouse')}}</option>
                                                <option value="farm">{{$t('reply.request.property.farm')}}</option>
                                                <option value="ranch">{{$t('reply.request.property.ranch')}}</option>
                                            </template>
                                        </b-form-select>
                                    </template>
                                </b-form-tags>
                            </b-form-group>
                        </template>
                    </card>
                </div>
            </div>
        </card>
    </section>
</template>

<script>
    import $ from 'jquery';
    import {
        BFormGroup, BFormSelect, BFormTag,
        BFormTags, } from 'bootstrap-vue'

    export default {
        name: 'Preference',
        components: {
            BFormGroup,
            BFormTag,
            BFormTags,
            BFormSelect
        },

        data() {
            return {
                options: ['apartment', 'house', 'store', 'condo', 'penthouse', 'kitchenette', 'land', 'loft', 'studio', 'office', 'shed', 'garage', 'warehouse', 'farm', 'ranch',],
                value: [],
                model: {
                    notification: {
                        all: true,
                    }
                },

                alert_message: '',
                alert_type: 'warning',
                alert: false,
                desktop: screen.width > 700,
            }
        },
        computed: {
            availableOptions() {
                return this.options.filter(opt => this.value.indexOf(opt) === -1);
            }
        },
        methods: {
            submitModel() {
                var gtag = this.$gtag;

                $.ajax({
                    url: 'https://api.immobl.com/preference',
                    contentType: 'application/json;charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    type: 'POST',
                    data: JSON.stringify({ data: this.model }),
                    success: function (response) {
                        if (response.success) {
                            gtag.event('save_preference');
                        }
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //}
                });

                alert(this.$data, 'success', this.$t('warning.preference.success'));                   

                function alert(data, type, message) {
                    data.alert = true;
                    data.alert_type = type;
                    data.alert_message = message;
                    $("html, body").animate({ scrollTop: 0 }, 1000);
                }
            },
            updateModel(document) {
                this.model = { ...this.model, ...document };
            }
        },

        async mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });

            var updateModel = this.updateModel;
            $.ajax({
                url: 'https://api.immobl.com/preference',
                contentType: 'application/json;charset=UTF-8',        
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                type: 'GET',
                success: function (response) {                                         
                    if (response.success && response.document != null) {
                        updateModel(response.document);
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });
        }
    }
</script>

<style scoped>
    .form {
        width: 50%;
        margin-left: 6px;
    }

    .alert-border {
        border-color: red;
    }
</style>